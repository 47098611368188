import { isArray, isBrowser, merge, pickBy, useLocalStorage } from '@sortlist-frontend/utils';
import { type NextRouter } from 'next/router';

import { config } from '../defaults';
import { EnabledForConfig, Environment, FeatureFlagSetup, MergedFeatureToggles } from './types';

const FEATURE_FLAGS_OVERRIDE_PARAM = 'feature-flags';
const FEATURE_FLAGS_OVERRIDE_ASSIGNATOR = ':';
const FEATURE_FLAGS_OVERRIDE_SEPARATOR = ',';

export function useExtractFeatureFlagOverrides(query: NextRouter['query']): FeatureFlagSetup {
  const params = query[FEATURE_FLAGS_OVERRIDE_PARAM];

  const configOverrideFromParams = extractConfigFromParams(params);
  const configOverrideFromLocalStorage = extractConfigFromLocalStorage();
  const configOverrideFromEnvironment = determineConfigFromEnvironment();

  const configOverride = merge(
    {},
    configOverrideFromLocalStorage,
    configOverrideFromParams,
    configOverrideFromEnvironment,
  );
  return { configOverride };
}

function extractConfigFromParams(params?: string | string[]) {
  if (params == null || isArray(params)) {
    return {};
  }

  return stringToConfigObject(params);
}

function extractConfigFromLocalStorage() {
  const [value] = useLocalStorage(FEATURE_FLAGS_OVERRIDE_PARAM, undefined, { deserializer: (x) => x });

  if (value == null) {
    return {};
  }

  return stringToConfigObject(value);
}

function stringToConfigObject(value: string): Partial<MergedFeatureToggles> {
  return value.split(FEATURE_FLAGS_OVERRIDE_SEPARATOR).reduce((memo, config: string) => {
    // [0] is the feature flag name
    // [1] is the defaultValue value
    const chunks = config.split(FEATURE_FLAGS_OVERRIDE_ASSIGNATOR);
    return {
      ...memo,
      [chunks[0]]: {
        defaultValue: chunks[1],
      },
    };
  }, {});
}

function determineConfigFromEnvironment() {
  if (!isBrowser()) {
    return {};
  }

  const environment = getEnvironmentFromHostname(window.location.hostname);

  if (environment == null) {
    return {};
  }

  const environments = isArray(environment) ? environment : [environment];
  const flagsWithEnvironmentConfig = pickBy(config, (value) => 'enabledForEnvironments' in value);

  return Object.entries(flagsWithEnvironmentConfig).reduce((memo, [key, value]) => {
    if ((value as EnabledForConfig).enabledForEnvironments?.some((enabledEnv) => environments.includes(enabledEnv))) {
      return {
        ...memo,
        [key]: {
          defaultValue: true,
        },
      };
    }
    return memo;
  }, {});
}

function getEnvironmentFromHostname(hostname: string): Environment | Environment[] | undefined {
  if (hostname === 'localhost') {
    return 'development';
  }

  if (hostname.endsWith('sandbox.sortlist.cloud')) {
    const sandboxId = hostname.match(/\d+/)?.[0];
    if (sandboxId == null) {
      return undefined;
    }
    return [`sandbox-${Number(sandboxId)}`, 'sandbox'];
  }

  if (hostname.endsWith('sortlist-test.com')) {
    return 'staging';
  }

  if (hostname.endsWith('sortlist.')) {
    // This shouldn't log an error, but for production we skip this check, and instead
    // should enable the flag by default
    return undefined;
  }

  console.error(`${hostname} is not a supported environment`);
  return undefined;
}
